import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import CommoSection from '../UI/CommoSection';
import { Container, Row, Col, Button } from 'reactstrap';
import { motion } from 'framer-motion';
import './ressource.css'
import { useLocation, Link } from 'react-router-dom';
import Marquee from "react-fast-marquee";

import { useTranslation } from 'react-i18next';


import B1 from '../../assets/images/site/Reactjs.png'
import B2 from '../../assets/images/site/android.webp'
import B3 from '../../assets/images/site/ios.webp'




import b1 from '../../assets/images/site/a1.png'

import b2 from '../../assets/images/site/mobile.jpg'




const Mobile = () => {

  const location = useLocation();

  const { t, i18n } = useTranslation();

  const [isDataReady, setIsDataReady] = useState(false);
 
  useEffect(() => {
    if (i18n.language && !isDataReady) {
      setIsDataReady(true);
    }
  }, [i18n.language, isDataReady]);



  const boxRefs = useRef([]);

  const [visibleBox, setVisibleBox] = useState(Array(5).fill(false));


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;

      boxRefs.current.forEach((ref, index) => {
        // Vérifier si l'élément est dans la vue
        if (ref && ref.offsetTop < scrollTop + windowHeight) {
          // Utiliser setTimeout pour ajouter un délai progressif
          const timeoutId = setTimeout(() => {
            setVisibleBox((prevVisibleBox) => {
              const newVisibleBox = [...prevVisibleBox];
              newVisibleBox[index] = true;
              return newVisibleBox;
            });
          }, index * 1000); // Chaque élément apparaît avec un délai de 200ms d'intervalle
          // Conserver l'ID du timeout pour le nettoyage ultérieur
          timeoutIds.push(timeoutId);
        }
      });
    };

    // Utiliser un tableau de références pour les timeouts
    const timeoutIds = [];

    // Écouter les événements de scroll
    window.addEventListener('scroll', handleScroll);

    // Nettoyer les timeouts lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
      // Nettoyer tous les timeouts enregistrés
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, []); // Le tableau vide [] assure que cet effet s'exécute uniquement une fois après le montage initial


   const boxesData = [
    {
      title: t('home.mob'),
    },
    {
      entete: t('service2.mobile1') ,
    },
    {
      offre: t('service2.offre'),
      },
    {
      
     titre: t('service2.mobile2') ,
      text: t('service2.mobile3'),
    },
    {
      titre: t('service2.mobile4'),
      text: t('service2.mobile5'),
    },
    {
      titre: t('service2.mobile6'),
      text: t('service2.mobile7'),
    },
    {
      titre: t('service2.mobile8'),
      text: t('service2.mobile9'),
    },
    {
      titre: t('service2.mobile10'),
      text: t('service2.mobile11'),
    },
    {
      titre: t('service2.mobile12'),
      text: t('service2.mobile13'),
    },
    {
      tectitre: t('service2.mobile14'),
    },
    {
      tectext: t('service2.mobile15') ,
    },
   
  ];



  const boxVariant = {
    hidden: { opacity: 0, x: -500 },
    visible: { opacity: 1, x: 0 },
  };


  const handleLink = (path) => () => {
    window.location.href = path;
  };
 

  

  return < >
    <Helmet>
      <title>Services - Applications Mobiles </title>
      <meta name="description" content=" Développement application mobile" />
    </Helmet>
    <CommoSection title= {t('home.mob')} />
    <section className=" servi  ">
      <Container>
        <Row className="service_p  ">

          <Col lg='7'>
            <div>
              <div className='m-auto text-center serimg'>
                <img src={b1} className="img-fluid rounded-start p-2 " alt="..." />
              </div>
            </div>
          </Col >


          <Col lg="5" className="ser">
              <div className="card-body ">
              <div className=' text-center'>
                  <h1 className="card-title">{ t('service1.solu')}  </h1>
                </div>
                <ul className="navlink">
                  <li>
                    <Link
                      onClick={handleLink('/siteweb')}
                      className={location.pathname === '/siteweb' ? 'active' : 'inactive'}
                    >
                      {t('home.siteweb')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={handleLink('/application-mobile')}
                      className={location.pathname === '/application-mobile' ? 'active' : 'inactive'}
                    >
                      {t('home.mob')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={handleLink('/reseau')}
                      className={location.pathname === '/reseau' ? 'active' : 'inactive'}
                    >
                      {t('home.res')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={handleLink('/infographie_video')}
                      className={location.pathname === '/infographie_video' ? 'active' : 'inactive'}
                    >
                      {t('home.info')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={handleLink('/api')}
                      className={location.pathname === '/api' ? 'active' : 'inactive'}
                    >
                      {t('home.ap')}
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>


        </Row>
      </Container>


      <Container>
        <Row className=" mt-5">
          <Col lg='7'>
            
              <div className='p-2'>
                {boxesData.map((box, index) => (
                  <motion.div
                    key={index}
                    className='pt-2'
                    initial="hidden"
                    animate={visibleBox[index] ? "visible" : "hidden"}
                    variants={boxVariant}
                    transition={{ duration: 0.5 }}
                    ref={(el) => (boxRefs.current[index] = el)}
                  >
                    <div className="text-center align-items-center  ">
                      <span className='title'> {box.title}  </span>
                    </div>
                    <span className='seroffre' >{box.offre}</span>
                    <span  >{box.entete}</span>
                    <span className=' p-3'><span className='titre'> {box.titre} </span>{box.text}</span>
                  </motion.div>
                ))}
              </div>
            
          </Col >

          <Col lg="5">
            <div className="d-flex justify-content-center align-items-center  imgsite"  style={{ height: '100%' }}>
              <img src={b2} className="img-fluid" alt="..." />
            </div>
          </Col>



        </Row>
      </Container>

    </section>

    <div className=' mobile '>
        <Container>
          <Row className="d-flex justify-content-center align-items-center " style={{ height: '100%' }}>
            <Col lg='7'>
              <div>
                <div className='p-2'>
                  {boxesData.map((box, index) => (
                    <motion.div
                      key={index}
                      initial="hidden"
                      animate={visibleBox[index] ? "visible" : "hidden"}
                      variants={boxVariant}
                      transition={{ duration: 0.5 }}
                      ref={(el) => (boxRefs.current[index] = el)}
                    >
                      <div className="text-center align-items-center mb-3 ">
                        <span className='title '> {box.tectitre}  </span>
                      </div>
                      <span className='mt-4' > {box.tectext}</span>
                    </motion.div>
                  ))}
                </div>
              </div>
            </Col >

            <Col lg="5" className='p-3 '>
              <Row>
                <Col lg='6'>
                  <motion.div whileTap={{ scale: 1.2 }}>
                    <img src={B1} className="img-fluid" alt="..." />
                  </motion.div>
                </Col>
                <Col lg='6' className='mt-5'>
                  <motion.div whileTap={{ scale: 1.2 }} >
                    <img src={B2} className="img-fluid" alt="..." />
                  </motion.div>
                </Col>
              </Row>
              <Row className='mt-5'>
                <Col lg='6'>
                  <motion.div whileTap={{ scale: 1.2 }}>
                    <img src={B3} className="img-fluid" alt="..." />
                  </motion.div>
                </Col>

              </Row>
            </Col>
          </Row>
        </Container>

    </div>


  </>


}

export default Mobile
