
import React, { useState, useEffect, useRef } from 'react';
import './footer.css'

import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import './footer.css'

import { useTranslation } from 'react-i18next';



const Footer = () => {


  const { t, i18n } = useTranslation();
  const [isDataReady, setIsDataReady] = useState(false);


  useEffect(() => {
    if (i18n.language && !isDataReady) {
      setIsDataReady(true);
    }
  }, [i18n.language, isDataReady]);

  const handleLinksiteweb = (e) => {
    window.location.href = '/siteweb';
  };

  const handleLinkreseau = (e) => {
    window.location.href = '/reseau';
  };

  const handleLinkapi = (e) => {
    window.location.href = '/api';
  };

  const handleLinkinfogra = (e) => {
    window.location.href = '/infographie_video';
  };

  const handleLinkmobile = (e) => {
    window.location.href = '/application-mobile';
  };


  const handleLinkcontact = (e) => {
    window.location.href = '/contact';
  };

  return (
    <footer className='footer p-3 mt-5'>
      <Container>

        <Row >

          <Col lg="5" className='mb-4' md='8'>
            <div className="footer__quick-links explora">

              <h1 className='text-white '>PhenixTec </h1>

            </div>
            <div className='phenitec'>
              <p className="footer__text mt-4 ">
                {t('home.footer')}
              </p>
            </div>

          </Col>


          <Col lg="4" className='mb-4' md='4'>
            <div className="footer__quick-links">
              <h4 className="quick__links-title"> {t('home.lien')} </h4>
              <ListGroup className='mb-3'>
                <ListGroupItem className='ps-0 border-0'>
                  <Link onClick={handleLinkcontact} >{t('home.contact')} </Link>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0'>
                  <Link onClick={handleLinksiteweb} > {t('home.siteweb')} </Link>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0'>
                  <Link onClick={handleLinkmobile} > {t('home.mob')} </Link>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0'>
                  <Link onClick={handleLinkreseau} > {t('home.res')} </Link>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0'>
                  <Link onClick={handleLinkinfogra} > {t('home.info')} </Link>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0'>
                  <Link onClick={handleLinkapi} > {t('home.ap')} </Link>
                </ListGroupItem>

              </ListGroup>

            </div>
          </Col>

          <Col lg="3" md='4'>
            <div className="footer__quick-links">
              <h4 className="quick__links-title">Contact</h4>
              <ListGroup className='footrt__contact'>
                <ListGroupItem className='ps-0 border-0 d-flex align-item-center gap-2'>
                  <span ><i class="ri-map-pin-line"></i></span>
                  <span>Lomé, Togo</span>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0 d-flex align-item-center gap-2 '>
                  <div className='d-flex align-item-center'>
                    <span ><i class="ri-phone-line"></i></span>
                    <span> +228 90008780</span>
                  </div>

                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0 d-flex align-item-center gap-2'>
                  <span ><i class="ri-mail-line"></i></span>
                  <span >mrphenixtec@gmail.com</span>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>

        </Row>
        <hr />

        <div>
          <p className="footer__copyright"> © 2024 phenixtec </p>
        </div>

      </Container>

    </footer>
  )
}

export default Footer
