import React, { useState } from 'react'
import { Container, Row, Col, Form, FormGroup,Input } from 'reactstrap';

import Helmet from '../components/Helmet/Helmet';
import '../styles/login.css';
import '../styles/dashboard.css'

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";


import { setDoc, doc, serverTimestamp } from 'firebase/firestore';

import { auth } from '../firebase.config';

import { db } from '../firebase.config';

import { toast } from 'react-toastify';



const Signup = () => {

  const [enterfirstname, setEnterFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [enterphone, setEnterPhone] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate()

  const signup = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;


      // Store user data in Firestore database
      await setDoc(doc(db, "utilisateur", user.uid), {
        uid: user.uid,
        FirstName: enterfirstname,
        Phone: enterphone,
        Date: serverTimestamp(),
        email,
      });

      toast.success("Création de compte réussie");
      navigate('/dashboard/login');
    } catch (error) {
     
      toast.error('Quelque chose s\'est mal passé');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return <Helmet title="Signup">
    <section>
      <Container>
        <Row>
          
                               : 
              <Col lg='5' className='m-auto text-center m-4'>
              <h3 className='fw-bold mb-4'>Inscription</h3>
  
              <Form className="auth__form" onSubmit={signup}>

              <FormGroup   >
                    <Input
                      type="text"
                      name="firstname"
                      placeholder="Entrez votre nom"
                      value={enterfirstname}
                      onChange={(e) => setEnterFirstName(e.target.value)}
                      required
                     
                    />
                  </FormGroup>

                
  
                <FormGroup  >
                  <Input 
                  type="email" 
                  placeholder='Entrez votre email'
                    value={email} 
                    onChange={e => setEmail(e.target.value)} 
                     required />
                </FormGroup>

                <FormGroup >
                    <Input
                      type="text"
                      name="phone"
                      placeholder="Entrez numero de telephone"
                      value={enterphone}
                      onChange={(e) => setEnterPhone(e.target.value)}
                      required
                      
                    />
                  </FormGroup>
  
                  <FormGroup className="position-relative">
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Entrez votre mot de passe"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <i
                      className={`mdi ${showPassword ? 'ri-eye-off-line' : 'ri-eye-line'} position-absolute`}
                      style={{ right: 10, top: 10, cursor: 'pointer' }}
                      onClick={togglePasswordVisibility}
                    />
                  </FormGroup>
  
  
                <button type='submit' className="buy__b auth__btn">Creer un compte</button>
                <p className='text-white' >Vous avez déja un compte? {" "}
                  <Link to='/dashboard/login'>se Connecter</Link></p>
              </Form>
  
            </Col>
          
        </Row>
      </Container>
    </section>

  </Helmet>
}

export default Signup
