import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'


const firebaseConfig = {
  apiKey: "AIzaSyDL7MX6VXX7nX04BpjzEnxZDiaux_t6-ok",
  authDomain: "phenixtec-f1daa.firebaseapp.com",
  projectId: "phenixtec-f1daa",
  storageBucket: "phenixtec-f1daa.appspot.com",
  messagingSenderId: "203056041704",
  appId: "1:203056041704:web:8fdef04d579abdf8bc035d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)

export default app;