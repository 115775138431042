import "./App.css";
import Layout from "./components/Layout/Layout";
import i18next from 'i18next'
import { initReactI18next } from "react-i18next"
import Backend from 'i18next-http-backend'

i18next
.use(initReactI18next)
.use(Backend)
.init({
  fallbackLng: 'fr',
  backend: {
    loadPath: '/translations/{{lng}}/translations.json'
  }
})



function App() {
  return <Layout />
}

export default App;
