import React from 'react'

import Helmet from '../components/Helmet/Helmet';

import b3 from '../assets/images/404.png'




const NotFound = () => {

  return <Helmet title={"NotFound"} >
   
    <section className=" ">
    <div className="container">
                    <div className="row">
                        <div className="col-md-4 mx-auto text-center " style={{marginTop:'3rem'}}>
                            <h1><img className="img-fluid" src={b3} alt={404} /></h1>
                            <h1>Désolé! Page non trouvée.</h1>
                            <div className="mt-3">
                                <a href="/" className="btn  "><i className="mdi mdi-home" /> Aller à la page Accueil</a>
                            </div>
                        </div>
                    </div>
                </div>
    </section>


</Helmet>;
}

export default NotFound
