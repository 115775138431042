import React, { useState, useEffect } from 'react';
import Helmet from '../components/Helmet/Helmet';
import CommoSection from '../components/UI/CommoSection';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import '../styles/login.css';
import { motion } from 'framer-motion';
import { db } from '../firebase.config';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t, i18n } = useTranslation();

  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    if (i18n.language && !isDataReady) {
      setIsDataReady(true);
    }
  }, [i18n.language, isDataReady]);

  const [pays, setPays] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [nom, setNom] = useState('');
  const [entreprise, setEntreprise] = useState('');
  const [message, setMessage] = useState('');
  const [visible, setVisible] = useState(false);
  const [autrePays, setAutrePays] = useState('');

  const indicatifs = {
    'Togo': '+228',
    'France': '+33',
    'Canada': '+1',
    'USA': '+1',
    'Bénin': '+229',
    'Ghana': '+233',
    'Côte d\'Ivoire': '+225',
    'Nigeria': '+234',
    'Burkina Faso': '+226',
    'Mali': '+223',
    // Ajouter plus de pays et leurs indicatifs ici
  };

  const ajouterMessage = async (e) => {
    e.preventDefault();

    try {
      const docRef = collection(db, 'Message');
      await addDoc(docRef, {
        Pays: pays === 'Autre' ? autrePays : pays,
        Email: email,
        Telephone: telephone,
        Nom: nom,
        Entreprise: entreprise,
        Message: message,
        Date: serverTimestamp(),
      });

    
      toast.success('Message enregistré avec succès');
      setPays('');
      setEmail('');
      setTelephone('');
      setNom('');
      setEntreprise('');
      setMessage('');
      setAutrePays('');
      
      window.location.reload();
      
    } catch (error) {
      toast.error('Erreur lors de l\'envoi du message');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 1500);
  }, []);

  const boxVariants1 = {
    hidden: { opacity: 0, x: 500 },
    visible: { opacity: 1, x: 0 },
  };

  const handlePaysChange = (e) => {
    const selectedPays = e.target.value;
    setPays(selectedPays);
    setTelephone(indicatifs[selectedPays] || '');
    if (selectedPays !== 'Autre') {
      setAutrePays('');
    }
  };

  return (
    <Helmet title="Contact">
      <CommoSection title={t('contact.cont1')} />
      <section className="contact__prod" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
        <Container>
          <Row className='justify-content-center'>
            <Col lg='5'>
              <motion.div
                initial="hidden"
                animate={visible ? 'visible' : 'hidden'}
                variants={boxVariants1}
                transition={{ duration: 0.5 }}
              >
                <div className='m-auto text-center contnous'>
                  <h2>{t('contact.cont1')}</h2>
                </div>
                <Form onSubmit={ajouterMessage}>
                  <Label for="pays">{t('contact.cont2')} *</Label>
                  <FormGroup>
                    <Input
                      type="select"
                      name="pays"
                      value={pays}
                      onChange={handlePaysChange}
                      required
                      className='p-2'
                    >
                      <option value="" disabled>{t('contact.cont')}</option>
                      <option value="Togo">Togo</option>
                      <option value="France">France</option>
                      <option value="Canada">Canada</option>
                      <option value="USA">USA</option>
                      <option value="Bénin">Bénin</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Mali">Mali</option>
                      <option value="Autre">{t('contact.cont3')}</option>
                    </Input>
                  </FormGroup>
                  {pays === 'Autre' && (
                    <FormGroup>
                      <Label for="autrePays">*</Label>
                      <Input
                        type="text"
                        name="autrePays"
                        placeholder={t('contact.cont')}
                        value={autrePays}
                        onChange={(e) => setAutrePays(e.target.value)}
                        required
                        className='p-2'
                      />
                    </FormGroup>
                  )}
                  <Label for="telephone">{t('contact.cont6')}*</Label>
                  <FormGroup>
                    <Input
                      type="text"
                      name="telephone"
                      placeholder={t('contact.cont7')}
                      value={telephone}
                      onChange={(e) => setTelephone(e.target.value)}
                      required
                      className='p-2'
                    />
                  </FormGroup>
                  <Label for="nom">{t('contact.cont8')}*</Label>
                  <FormGroup>
                    <Input
                      type="text"
                      name="nom"
                      placeholder={t('contact.cont9')}
                      value={nom}
                      onChange={(e) => setNom(e.target.value)}
                      required
                      className='p-2'
                    />
                  </FormGroup>
                  <Label for="email">{t('contact.cont10')}*</Label>
                  <FormGroup>
                    <Input
                      type="email"
                      name="email"
                      placeholder="professionnel@gmail.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className='p-2'
                    />
                  </FormGroup>
                  <Label for="entreprise">{t('contact.cont11')}*</Label>
                  <FormGroup>
                    <Input
                      type="text"
                      name="entreprise"
                      placeholder={t('contact.cont12')}
                      value={entreprise}
                      onChange={(e) => setEntreprise(e.target.value)}
                      required
                      className='p-2'
                    />
                  </FormGroup>
                  <Label for="message">{t('contact.cont13')}*</Label>
                  <FormGroup>
                    <Input
                      type="textarea"
                      name="message"
                      placeholder={t('contact.cont14')}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                      className='p-2'
                    />
                  </FormGroup>
                  <div className='envoyer text-center'>
                    <Button type="submit" color="primary">{t('contact.cont15')}</Button>
                  </div>
                </Form>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
}

export default Contact;
