import React, { useState, useRef } from 'react';
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { db, storage } from '../firebase.config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';   
import useAuth from '../custom-hooks/useAuth';
import '../styles/dashboard.css';


const Addreal = () => {
    const [title, setTitle] = useState('');
    const [shortDesc, setShortDesc] = useState('');
    const [img, setImg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const navigate = useNavigate();
    const menuRef = useRef(null);

    const adminNav = [
        { display: 'Liste des Messages', path: '/dashboard__admin' },
        { display: 'Liste des Utilisateurs', path: '/dashboard__admin/users' },
        { display: 'Ajouter des Réalisations', path: '/dashboard__admin/ajouter_real' },
        { display: 'Inscription', path: '/dashboard/signup' },
    ];

    const toggleMenu = () => menuRef.current.classList.toggle('active__menu');

    const addProduct = async (e) => {
        e.preventDefault();

        if (submitted) return;

        setLoading(true);
        setSubmitted(true);

        try {
            const productsCollectionRef = collection(db, 'realisation');
            const storageRef = ref(storage, `RealisationImage/${Date.now()}_${img.name}`);
            const uploadTask = uploadBytesResumable(storageRef, img);

            uploadTask.on(
                'state_changed',
                null,
                () => toast.error('Échec de l\'upload de l\'image'),
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    await addDoc(productsCollectionRef, {
                        titre: title,
                        shortDesc: shortDesc,
                        date: serverTimestamp(),
                        imgUrl: downloadURL,
                    });
                    toast.success('Produit ajouté avec succès');
                    navigate('/dashboard__admin/list_real');
                }
            );
        } catch (error) {
            setLoading(false);
            toast.error('Erreur lors de l\'ajout du produit');
        }
    };

    return (
        <>
            <section className="admin__menu p-0">
                <Container>
                    <Row>
                        <div className="admin__navigation" ref={menuRef} onClick={toggleMenu}>
                            <ul className="admin__menu-list">
                                {adminNav.map((item, index) => (
                                    <li key={index} className="admin__menu-item">
                                        <NavLink
                                            to={item.path}
                                            className={({ isActive }) =>
                                                isActive ? 'active__admin-menu' : ''
                                            }
                                        >
                                            {item.display}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="nav__icons">
                            <div className="mobile__menu">
                                <span onClick={toggleMenu}>
                                    <i className="ri-menu-line"></i>
                                </span>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>

            <section>
                <Container>
                    <Row>
                        <Col lg="12">
                            {loading ? (
                                <h4 className="py-5">Chargement...</h4>
                            ) : (
                                <>
                                    <h4 className="mb-5 mt-1">Ajouter une Réalisation</h4>
                                    <Form onSubmit={addProduct}>
                                        <div className="d-flex align-items-center justify-content-between gap-5 mb-3">
                                            <FormGroup className="form__group w-50">
                                                <input
                                                    type="file"
                                                    onChange={(e) => setImg(e.target.files[0])}
                                                    required
                                                />
                                            </FormGroup>

                                            <FormGroup className="form__group w-50">
                                                <input
                                                    type="text"
                                                    placeholder="Nom du produit"
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    required
                                                />
                                            </FormGroup>
                                        </div>

                                        <FormGroup className="form__group w-100">
                                            <textarea
                                                placeholder="Détails..."
                                                value={shortDesc}
                                                onChange={(e) => setShortDesc(e.target.value)}
                                                required
                                            />
                                        </FormGroup>

                                        <button className="btn__submit" type="submit">
                                            Ajouter
                                        </button>
                                    </Form>
                                </>
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Addreal;
