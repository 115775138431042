import React, { useRef, useEffect,useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { useTranslation } from 'react-i18next';

import './service.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import B1 from '../../assets/images/creation-de-sites-web.png';
import B2 from '../../assets/images/reseau.png';
import B3 from '../../assets/images/application.png';
import B4 from '../../assets/images/api.png';
import B5 from '../../assets/images/infographie.png';

const Service = () => {
  const swiperRef = useRef(null);
  const { t, i18n } = useTranslation();

  const [isDataReady, setIsDataReady] = useState(false);
 
  useEffect(() => {
    if (i18n.language && !isDataReady) {
      setIsDataReady(true);
    }
  }, [i18n.language, isDataReady]);


  const services = [
    { image: B1, title: t('home.siteweb'), description: t('home.site'), link: './siteweb' },
    { image: B2, title: t('home.mob'), description: t('home.mobile'), link: './application-mobile' },
    { image: B3, title: t('home.res'), description: t('home.reseau'), link: './reseau' },
    { image: B4, title: t('home.ap'), description: t('home.api'), link: './api' },
    { image: B5, title: t('home.info'), description: t('home.infographie'), link: './Infographie_video' },
  ];

  return (
    <div className="service-container">
      <Swiper
        ref={swiperRef}
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        breakpoints={{
          640: { slidesPerView: 1, spaceBetween: 10 },
          768: { slidesPerView: 2, spaceBetween: 30 },
          1024: { slidesPerView: 3, spaceBetween: 40 },
        }}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 1500, disableOnInteraction: false }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={() => console.log('slide change')}
      >
        {services.map((service, index) => (
          <SwiperSlide
            key={index}
            onMouseEnter={() => swiperRef.current.autoplay.stop()}
            onMouseLeave={() => swiperRef.current.autoplay.start()}
          >
            <a href={service.link}>
              <div className="slide-content">
                <img src={service.image} alt={`Service ${index + 1}`} />
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Service;
