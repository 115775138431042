import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './ressource.css'
import { useTranslation } from 'react-i18next';
import { db } from '../../firebase.config';
import useGetsData from '../../custom-hooks/useGetsData';

import zombuy from '../../assets/images/realisation/eco-logo2.png'
import explora from '../../assets/images/realisation/eco-logo.png'
import roomlive from '../../assets/images/ressource/emb2.png'



import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';


const Realisation = () => {

  const [visible, setVisible] = useState(false); // State pour gérer la visibilité de la boîte
 

  const boxRefs = useRef(null);

  const { t } = useTranslation();


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;

      // Vérifier si l'élément est dans la vue
      if (boxRefs.current && boxRefs.current.offsetTop < scrollTop + windowHeight) {
        // Afficher la boîte après un délai
        setTimeout(() => {
          setVisible(true);
        }, 1500); // Délai de 1 seconde pour l'animation
      }
    };

    // Écouter les événements de scroll
    window.addEventListener('scroll', handleScroll);

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Le tableau vide [] assure que cet effet s'exécute uniquement une fois après le montage initial

  const boxVariants = {
    hidden: { opacity: 0, y: -500 },
    visible: { opacity: 1, y: 0 },
  };

 

  return < >
  
 <motion.div
              initial="hidden"
              animate={visible ? 'visible' : 'hidden'}
              variants={boxVariants}
              transition={{ duration: 0.5 }}
              ref={(el) => (boxRefs.current = el)}
            >
    <Row className='reali'>
   
      <Col lg='4'>
        <div className="card">
          <div className="cardreal ">
            <div className='imgrod m-auto text-center mb-2' >
              <img src={explora} alt="" />
            </div>
            <div className='m-auto text-center '>
              <span className="card-title">Explora customer services </span>
            </div>
            <p className="pt-2">
            {t('realisation.real1')}
            </p>

          </div>
        </div>
      </Col>


      <Col lg='4' >
        <div className="card">
          <div className="cardreal">
          <div className='imgrod m-auto text-center mb-2' >
              <img src={zombuy} alt="" />
            </div>
            <div className='m-auto text-center'>
              <span className="card-title">Zombuy </span>
            </div>
            <p className='pt-2'>
            {t('realisation.real2')}
            </p>
          </div>
        </div>
      </Col>
     

    </Row>
    </motion.div>
   
  </>;
};

export default Realisation;
