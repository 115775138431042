import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import CommoSection from '../UI/CommoSection';
import { Container, Row, Col, Button } from 'reactstrap';
import { motion } from 'framer-motion';
import './ressource.css'
import { useLocation, Link } from 'react-router-dom';
import Marquee from "react-fast-marquee";

import { useTranslation } from 'react-i18next';


import B1 from '../../assets/images/api/java.jpeg'

import B2 from '../../assets/images/api/google.png'
import B3 from '../../assets/images/api/finance.png'
import B4 from '../../assets/images/api/traduction.png'
import B5 from '../../assets/images/api/chatgpt.jpeg'




import b1 from '../../assets/images/api/api1.jpeg'

import b2 from '../../assets/images/api/api.jpg'




const Api = () => {

  const location = useLocation();

  
  const { t, i18n } = useTranslation();

  const [isDataReady, setIsDataReady] = useState(false);
  
  useEffect(() => {
    if (i18n.language && !isDataReady) {
      setIsDataReady(true);
    }
  }, [i18n.language, isDataReady]);

  const boxRefs = useRef([]);

  const [visibleBox, setVisibleBox] = useState(Array(5).fill(false));


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;

      boxRefs.current.forEach((ref, index) => {
        // Vérifier si l'élément est dans la vue
        if (ref && ref.offsetTop < scrollTop + windowHeight) {
          // Utiliser setTimeout pour ajouter un délai progressif
          const timeoutId = setTimeout(() => {
            setVisibleBox((prevVisibleBox) => {
              const newVisibleBox = [...prevVisibleBox];
              newVisibleBox[index] = true;
              return newVisibleBox;
            });
          }, index * 1000); // Chaque élément apparaît avec un délai de 200ms d'intervalle
          // Conserver l'ID du timeout pour le nettoyage ultérieur
          timeoutIds.push(timeoutId);
        }
      });
    };

    // Utiliser un tableau de références pour les timeouts
    const timeoutIds = [];

    // Écouter les événements de scroll
    window.addEventListener('scroll', handleScroll);

    // Nettoyer les timeouts lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
      // Nettoyer tous les timeouts enregistrés
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, []); // Le tableau vide [] assure que cet effet s'exécute uniquement une fois après le montage initial

  const boxesData = [
    {
      title:   t('home.ap')
    },
    {
      entete:  t('service4.api1'),
    },
    {
      offre:  t('service4.offre'),
    },
    {
      titre: t('service4.api2'),
      text: t('service4.api3'), 
    },
    {
      titre: t('service4.api4'),
      text: t('service4.api5')
    },
    {
      reacttitre: t('service4.api6')
    },
    {
      react: t('service4.api7')
    },

    {
      apititre: t('service4.api8')
    }


  ];


  const boxVariant = {
    hidden: { opacity: 0, x: -500 },
    visible: { opacity: 1, x: 0 },
  };



  const handleLinksiteweb = (e) => {
    window.location.href = '/siteweb';
  };

  const handleLinkreseau = (e) => {
    window.location.href = '/reseau';
  };

  const handleLinkinfogra = (e) => {
    window.location.href = '/infographie_video';
  };

  const handleLinkapi = (e) => {
    window.location.href = '/api';
  };


  const handleLinkmobile = (e) => {
    window.location.href = '/application-mobile';
  };

  return < >
    <Helmet>
      <title>Services - Api </title>
      <meta name="description" content=" Développement et Intégration d' Api " />
    </Helmet>
    <CommoSection title= {t('home.ap')} />
    <section className=" servi  m-3">
      <Container>
        <Row className="service_p  ">

          <Col lg='7'>
            <div>
              <div className='m-auto text-center serimg'>
                <img src={b1} className="img-fluid rounded-start p-2 " alt="..." />
              </div>
            </div>
          </Col >


          <Col lg='5' className='ser' >
            <div >
              <div className="card-body">
                <div className=' text-center'>
                  <h1 className="card-title">{ t('service1.solu')}  </h1>
                </div>
                <ul className="navlink">
                  <li >
                    <Link onClick={handleLinksiteweb} className={location.pathname === '/siteweb' ? 'active' : 'inactive'}>
                    { t('home.siteweb')}  
                    </Link>
                  </li>
                  <li >
                    <Link onClick={handleLinkmobile} className={location.pathname === '/application-mobile' ? 'active' : 'inactive'}>
                    { t('home.mob')}  
                    </Link>
                  </li>
                  <li >
                    <Link onClick={handleLinkreseau} className={location.pathname === '/reseau' ? 'active' : 'inactive'}>
                    { t('home.res')}  
                    </Link>
                  </li>
                  <li >
                    <Link onClick={handleLinkinfogra} className={location.pathname === '/infographie' ? 'active' : 'inactive'}>
                    { t('home.info')}  
                    </Link>
                  </li>
                  <li >
                    <Link onClick={handleLinkapi} className={location.pathname === '/api' ? 'active' : 'inactive'}>
                    { t('home.ap')}  
                    </Link>
                  </li>
                </ul>

              </div>
            </div>
          </Col>


        </Row>
      </Container>

      <Container>
        <Row className=" mt-5">
          <Col lg='7'>
            
              <div >
                {boxesData.map((box, index) => (
                  <motion.div
                    key={index}
                    className='pt-2'
                    initial="hidden"
                    animate={visibleBox[index] ? "visible" : "hidden"}
                    variants={boxVariant}
                    transition={{ duration: 0.5 }}
                    ref={(el) => (boxRefs.current[index] = el)}
                  >
                    <div className="text-center align-items-center  ">
                      <span className='title '> {box.title}  </span>
                    </div>
                    <span className='seroffre' >{box.offre}</span>
                    <span  >{box.entete}</span>
                    <span className=' p-3 '><span className='titre'> {box.titre} </span>{box.text}</span>
                  </motion.div>
                ))}
            </div>
          </Col >

          <Col lg="5">
            <div className="d-flex justify-content-center align-items-center imgsite" >
              <img src={b2} className="img-fluid" alt="..." />
            </div>
          </Col>
        </Row>
      </Container>


      <Container>
        <Row className="  d-flex justify-content-center align-items-center ">
          <Col lg='7'>
            <div >
              {boxesData.map((box, index) => (
                <motion.div
                  key={index}
                  initial="hidden"
                  animate={visibleBox[index] ? "visible" : "hidden"}
                  variants={boxVariant}
                  transition={{ duration: 0.5 }}
                  ref={(el) => (boxRefs.current[index] = el)}
                >
                  <div className="text-center align-items-center mb-3  ">
                    <span className='reactitre '> {box.reacttitre}  </span>
                  </div>

                  <span >{box.react}</span>
                </motion.div>
              ))}
            </div>
          </Col >

          <Col lg="5">
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
              <img src={B1} className="img-fluid" alt="..." />
            </div>
          </Col>



        </Row>
      </Container>

      <div className='mt-5'>
              {boxesData.map((box, index) => (
                <motion.div
                  key={index}
                  initial="hidden"
                  animate={visibleBox[index] ? "visible" : "hidden"}
                  variants={boxVariant}
                  transition={{ duration: 0.5 }}
                  ref={(el) => (boxRefs.current[index] = el)}
                >
                  <div className="text-center align-items-center mb-3  ">
                    <span className='reactitre '> {box.apititre}  </span>
                  </div>

                </motion.div>
              ))}
            </div>
      <Container>
          <div className=" mt-2 d-flex justify-content-center align-items-center reseau">
          <Marquee className="p-2 slide d-flex front">
          <div className="">
            <img src={B2} alt="" className="img-fluid" />
          </div>
          <div className="">
            <img src={B3} alt="" className="img-fluid" />
          </div>
          <div className="">
            <img src={B4} alt="" className="img-fluid" />
          </div>
          <div className="">
            <img src={B5} alt="" className="img-fluid" />
          </div>
        </Marquee>
          </div>
        </Container>
    </section>







  </>


}

export default Api
