import React from 'react'

import { Container, Row, Col } from 'reactstrap';
import useGetsData from '../custom-hooks/useGetsData';
import { db } from '../firebase.config';
import { doc, deleteDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';


const List_real = () => {

  const { data: productsData, loading  } = useGetsData('realisation')

  //==== firebase firstore realtime data uptate

  const deleteProduct = async(id) => {
    await deleteDoc(doc(db, 'realisation', id))
    toast.success('Suppression effectuer !')
  }

 
  return (
  <Container>
    <Row>
      <Col lg='12'>
      <table className='table mt-5'>
      <thead>
      <tr>
        <th>Image</th>
        <th>Titre</th>
        <th>Description</th>
        <th>Action</th>
        </tr>
      </thead>
      <tbody>
      { loading ? (<h3 className='py-5 fw-bold'>loading....</h3>) :( productsData.map(item => (  
              <tr key={item.id}>
              <td><img src={item.imgUrl} alt="" /> </td>
              <td>{item.titre} </td>
              <td>{item.shortDesc} </td>
              <td>
              <button onClick={() => {deleteProduct(item.id)}} className="btn btn-danger">Delete</button>
              </td>
            </tr>
            )))
      }
      </tbody>
      </table>
      </Col>
    </Row>
  </Container>
  )
}

export default List_real
